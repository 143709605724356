import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MissionService {

  // Observable string sources
  private missionAnnouncedSource = new Subject<string>();
  private missionConfirmedSource = new Subject<any>();

  private missionCleanTopBar = new Subject<string>();

  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();
  missionCleanTopBar$ = this.missionCleanTopBar.asObservable();

  // Service message commands
  announceMission(mission: string) {
    this.missionAnnouncedSource.next(mission);
  }

  confirmMission(astronaut: any) {
    // console.log(astronaut);
    this.missionConfirmedSource.next(astronaut);
  }

  cleanTopBar(){
    this.missionCleanTopBar.next('clean');
  }
}