<input type="file" (change)="fileChangeEvent($event)" />

<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 3" format="png"
    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" [roundCropper]="true"
    (loadImageFailed)="loadImageFailed()"></image-cropper>

<!-- uncomment to display a preview of the cropped image <img [src]="croppedImage" /> -->

<div class ="form-buttons-wrapper">
    <button class="button" (click)="save()" [disabled]="croppedImage === ''">Save</button>
    <button class="button" (click)="close()">Cancel</button>
</div>