<ngx-charts-bar-vertical-2d
  [scheme]="colourScheme"
  [results]="dataset"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [legendTitle]="legendTitle"
  [barPadding]="0"
>
</ngx-charts-bar-vertical-2d>
