<div style="display: flex; flex-direction: row;">
    <div style="display: flex; flex-direction: column; padding: 5em">
        <mat-checkbox (change)="updateFilter($event, 'attack')" [checked]="displayAttackParams">Attack parameters</mat-checkbox>
        
        <div *ngIf="displayAttackParams" class="parameters-wrapper">
            <mat-checkbox  *ngFor="let param of attackParams" (change)="changeParameterSelection($event, param)">{{param}}</mat-checkbox>
        </div>
        
        <hr>

        <mat-checkbox (change)="updateFilter($event, 'defense')" [checked]="displayDefenseParams">Defense parameters</mat-checkbox>
        
        <div *ngIf="displayDefenseParams" class="parameters-wrapper"> 
            <mat-checkbox *ngFor="let param of defenseParams" (change)="changeParameterSelection($event, param)">{{param}}</mat-checkbox>
        </div>
    </div>

    <app-histogram-chart *ngIf="dataset?.length > 0" [view]='chartSize' [dataset]='dataset'></app-histogram-chart>
</div>




