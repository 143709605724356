import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-update-picture-modal',
  templateUrl: './update-picture-modal.component.html',
  styleUrls: ['./update-picture-modal.component.scss']
})
export class UpdatePictureModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UpdatePictureModalComponent>) {
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  ngOnInit() { }


  save() {
    this.dialogRef.close(this.croppedImage);
  }


  close() {
    this.dialogRef.close();
  }
}
