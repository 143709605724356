import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main-page',
    pathMatch: 'full',
    canActivate: [LoginGuard],
  },
  {
    path: 'main-page',
    loadChildren: () =>
      import('./main-page/main-page.module').then((m) => m.MainPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'team-management',
    loadChildren: () =>
      import('./team-management/team-management.module').then(
        (m) => m.TeamManagementModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'club-management',
    loadChildren: () =>
      import('./features/club-management/club-management.module').then(
        (m) => m.ClubManagementModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
    canActivate: [LoginGuard],
  },

  {
    path: 'gameanalytics',
    loadChildren: () =>
      import('./features/gameanalytics/gameanalytics.module').then(
        (m) => m.GameanalyticsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'playeranalytics',
    loadChildren: () =>
      import('./features/playeranalytics/playeranalytics.module').then(
        (m) => m.PlayeranalyticsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'teamanalytics',
    loadChildren: () =>
      import('./features/teamanalytics/teamanalytics.module').then(
        (m) => m.TeamanalyticsModule
      ),
    canActivate: [LoginGuard],
  },

  {
    path: 'game-management',
    loadChildren: () =>
      import('./game-management/game-management.module').then(
        (m) => m.GameManagementModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./features/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [LoginGuard],
  },
  {
    path: '**',
    redirectTo: 'main-page',
    pathMatch: 'full',
    canActivate: [LoginGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
