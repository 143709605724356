import { Component, Input, OnChanges } from '@angular/core';
import { IAggregatedAnalytics, IChartModel } from '@shared/models/analytics';
import { IGame } from '@shared/models/game';

@Component({
  selector: 'app-team-analytics',
  templateUrl: './team-analytics.component.html',
  styleUrls: ['./team-analytics.component.scss']
})
export class TeamAnalyticsComponent implements OnChanges {

  @Input() analytics: IAggregatedAnalytics;
  @Input() chartSize: [];
  @Input() games: IGame[];

  dataset: IChartModel[] = [];

  displayAttackParams: boolean;
  displayDefenseParams: boolean;

  attackParams: string[];
  defenseParams: string[];

  selectedParams: string[] = [];

  constructor() { }

  ngOnChanges(): void {

    this._resetState();
    this._computeParams();
    this._resetSelectedParams();

  }

  private _resetState() {
    this.displayAttackParams = this.displayDefenseParams = false;
    this.attackParams = [];
    this.defenseParams = [];
    this.selectedParams = [];
    this.dataset = [];
  }

  private _computeParams() {

    if (this.analytics.attackAnalytics?.length > 0) {

      for (const teamAnalytics of this.analytics.attackAnalytics) {
        const teamAttParams = Object.keys(teamAnalytics.params);

        this.attackParams = [...new Set(this.attackParams.concat(teamAttParams))];
      }
    }

    if (this.analytics.defenseAnalytics?.length > 0) {

      for (const teamAnalytics of this.analytics.defenseAnalytics) {
        const teamDefParams = Object.keys(teamAnalytics.params);

        this.defenseParams = [...new Set(this.defenseParams.concat(teamDefParams))];
      }
    }
  }

  // TODO - these should be included in a shared component
  changeParameterSelection(event: any, param: string) {

    if (event.checked) {
      this.selectedParams.push(param);
    }
    else {
      const index = this.selectedParams.findIndex(p => p === param);

      if (index !== -1) {
        this.selectedParams.splice(index, 1);
      }
    }

    this._updateDataset();
  }

  private _getGameName(gameID: string) {
    return this.games.find(g => g.gameID === gameID).gameName;
  }

  private _updateDataset() {

    const results = {};

    for (const selectedParam of this.selectedParams) {
      results[selectedParam] = [];
    }

    for (const attAnalytics of this.analytics.attackAnalytics) {
      for (const [key, value] of Object.entries(attAnalytics.params)) {
        if (results[key] != null) {
          results[key].push({
            name: this._getGameName(attAnalytics.gameID),
            value
          });
        }
      }
    }

    for (const defAnalytics of this.analytics.defenseAnalytics) {
      for (const [key, value] of Object.entries(defAnalytics.params)) {
        if (results[key] != null) {
          results[key].push({
            name: this._getGameName(defAnalytics.gameID),
            value
          });
        }
      }
    }

    this.dataset = Object.keys(results).map((key) => {
      return {
        name: key,
        series: results[key]
      };
    });
   }

  private _resetSelectedParams() {

    for (let i = 0; i < this.selectedParams.length; ++i) {
      if (this.displayAttackParams === false) {
        if (this.attackParams.includes(this.selectedParams[i])) {
          this.selectedParams.splice(i);
          --i;
        }
      }

      if (this.displayDefenseParams === false) {
        if (this.defenseParams.includes(this.selectedParams[i])) {
          this.selectedParams.splice(i);
          --i;
        }
      }
    }
  }

  updateFilter(event: any, type: string) {

    if (type === 'attack') {
      this.displayAttackParams = event.checked;
    }
    else {
      this.displayDefenseParams = event.checked;
    }

    this._resetSelectedParams();
    this._updateDataset();
  }

}
