<div class="menu-bar">
  <div class="title">
    <div class="title-icon">
      <img src="/assets/images/handball.svg" />
    </div>
    {{ brandName }}
  </div>
  <div class="user-info" (click)="goToAccountPage()">
    <div>
      <img src="/assets/images/account.svg" class="bigimage" />
    </div>
    <div *ngIf="operator" class="button">
      {{ operator.firstName }} {{ operator.lastName }}
    </div>
  </div>
</div>
