import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { IError } from '@shared/models/api';
import { RequestCacheService } from './request-cache.service';
import { Router } from '@angular/router';


@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private cache: RequestCacheService, private readonly router: Router) { }

  private refreshSubject: Subject<any> = new Subject<any>();


  private _ifTokenExpired() {
    this.refreshSubject.subscribe({
      complete: () => {
        this.refreshSubject = new Subject<any>();
      }
    });
    if (this.refreshSubject.observers.length === 1) {
      this.authService.refreshAccessToken().subscribe(this.refreshSubject);
    }
    return this.refreshSubject;
  }

  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    
    const apiError: IError = error.error.error;

    if (apiError == null) {
      return false;
    }

    if (apiError.type === 'user') {
      const errorMessage = apiError.messages[0]['user'];

      return errorMessage === 'token expired';
    }

    return false;
  }


  private _addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {

    const accessToken = this.authService.accessToken;

    const apiReq = request.clone({
      url: request.url,
      setHeaders: {
        'Access-Token': accessToken
      }
    });

    return apiReq;
  }

  private _handleResponseError(error: HttpErrorResponse, request?: HttpRequest<any>, next?: HttpHandler): Observable<HttpEvent<any>> {

    if (this._checkTokenExpiryErr(error)) {
      // if token expired logout of the damn app
      localStorage.removeItem('accessKey');
      localStorage.removeItem('changePassword');
      localStorage.removeItem('deviceID');
      localStorage.removeItem('email');
      localStorage.removeItem('refreshKey');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('username');
      this.router.navigate(['/login']);
      // return this._ifTokenExpired().pipe(
      //   switchMap(() => {
      //     return next.handle(this._addAuthHeader(request));
      //   })
      // );
    }
    else {
      return throwError(error);
    }
  }

  // All HTTP requests are going to go through this method
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.isLoggedIn()) {

      if (request.method === 'GET') {
        const cachedResponse = this.cache.get(request);

        if (cachedResponse) {
          return of(cachedResponse);
        }
      }
      else {
        this.cache.clearCache();
      }

      const apiReq = this._addAuthHeader(request);

      return next.handle(apiReq).pipe(

        catchError((error) => {
          return this._handleResponseError(error, request, next);
        }),
        tap(event => {
          if (apiReq.method === 'GET' && event instanceof HttpResponse) {
            this.cache.put(request, event);
          }
        })
      ) as any;
    }
    else {
      this.authService.refreshAccessToken();
    }

    return next.handle(request);
  }
}
