import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-deletion-modal',
  templateUrl: './confirm-deletion-modal.component.html',
  styleUrls: ['./confirm-deletion-modal.component.scss']
})
export class ConfirmDeletionModalComponent implements OnInit {

  message: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeletionModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { confirmationMessage: string }) { }

  ngOnInit(): void {
    this.message = this.data.confirmationMessage;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
