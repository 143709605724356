import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from '@services/utility-services/interceptor.service';
import { RequestCacheService } from '@services/utility-services/request-cache.service';

import { Observable } from 'rxjs';

// import {
//   IMqttMessage,
//   MqttModule,
//   IMqttServiceOptions
// } from 'ngx-mqtt';

// export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
//   hostname: '192.168.1.186',
//   port: 30000,
//   path: ''
// };

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    // MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
  ],
  providers: [RequestCacheService, { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
