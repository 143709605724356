import { Component, Input, OnInit } from '@angular/core';
import { ICON_REGISTRY_PROVIDER_FACTORY } from '@angular/material/icon';
import { Router } from '@angular/router';
import { OperatorService } from '@services/api-services/operator.service';
import { MissionService } from '@services/utility-services/mission.service';
import { IOperator } from '@shared/models/operator';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [MissionService]
})
export class NavbarComponent implements OnInit {

  @Input() email: string;

  readonly brandName = 'DiveShot';

  operator: IOperator = null;

  constructor(
    private readonly router: Router,
    private readonly operatorService: OperatorService,
    private missionService: MissionService) {
      // missionService.missionConfirmed$.subscribe(
      //   astronaut => {
      //     console.log("astronaut");
      //     this.operator = null;
      //     this.operator = {operatorID:"",email:"",clubID:"",firstName: astronaut.data.firstName, lastName: astronaut.data.lastName};
      // });
    }


  goToAccountPage() {
    this.router.navigateByUrl('account');
  }

  ngOnInit() {
    this.operatorService.getClubAndOperatorInfo().subscribe(
      (res) => {
        // console.log(res);
        if (res.success === true) {
          this.operator = {operatorID:"",email:"",clubID:"",firstName: res.data.firstName, lastName: res.data.lastName};
        }
      }
    );
  }
}
