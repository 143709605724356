<div class="content-wrapper">
  <div class="menu-wrapper">
    <div class="menuTop">
      <div class="menu-card" (click)="goTo('main-page')">
        <div class="icon">
          <img src="/assets/images/dashboard.svg" class="icon-svg" />
        </div>
        <div class="menu-item-name">Dashboard</div>
      </div>
      <div class="menu-card" *ngIf="isEnabled" (click)="goTo('club-management')">
        <div class="icon">
          <img src="/assets/images/clubmanagement.svg" class="icon-svg" />
        </div>
        <div class="menu-item-name">Club Management</div>
      </div>
      <div class="menu-card" (click)="goTo('team-management')">
        <div class="icon">
          <img src="/assets/images/teammanagement.svg" class="icon-svg" />
        </div>
        <div class="menu-item-name">Team Management</div>
      </div>
      <!-- <div class="menu-card" (click)="goTo('analytics')">
        <div class="icon">
          <img src="/assets/images/bar-chart.svg" class="icon-svg" />
        </div>
        <div class="menu-item-name">Analytics</div>
      </div> -->

      <mat-expansion-panel class="expansion-panel">
        <mat-expansion-panel-header class="expansion-panel-header">
          <mat-panel-title class="expansion-panel-title">
            <div class="icon">
              <img src="/assets/images/insights.svg" class="icon-svg" />
            </div>
            Analytics
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="menu-card" (click)="goTo('gameanalytics')">
          <div class="icon">
            <img src="/assets/images/game.svg" class="icon-svg" />
          </div>
          <div class="menu-item-name">Game Analytics</div>
        </div>

        <div class="menu-card" (click)="goTo('playeranalytics')">
          <div class="icon">
            <img src="/assets/images/player.svg" class="icon-svg" />
          </div>
          <div class="menu-item-name">Player Analytics</div>
        </div>

        <div class="menu-card" (click)="goTo('teamanalytics')">
          <div class="icon">
            <img src="/assets/images/team.svg" class="icon-svg" />
          </div>
          <div class="menu-item-name">Team Analytics</div>
        </div>
      </mat-expansion-panel>
      <div class="menu-card" (click)="goTo('game-management')">
        <div class="icon">
          <img src="/assets/images/playlist.svg" class="icon-svg" />
        </div>
        <div class="menu-item-name">Game Management</div>
      </div>
    </div>

    <div class="menuBottom">
      <div class="menu-card" *ngIf="isEnabled" (click)="goTo('account')">
        <div class="icon">
          <img src="/assets/images/settings.svg" class="icon-svg" />
        </div>
        <div class="menu-item-name">Settings</div>
      </div>
      <div class="menu-card" (click)="logoutUser()">
        <div class="icon">
          <img src="/assets/images/exit.svg" class="icon-svg" />
        </div>
        <div class="menu-item-name">Log out</div>
      </div>
    </div>
  </div>
</div>
