import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-histogram-chart',
  templateUrl: './histogram-chart.component.html',
  styleUrls: ['./histogram-chart.component.scss']
})
export class HistogramChartComponent {

  @Input() dataset: any[];
  @Input() view: any[];
  @Input() legendTitle: string;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;

  colourScheme = {
    domain: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600']
  };

  constructor() {
  }
}
