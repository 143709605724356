import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { UpdatePictureModalComponent } from './components/update-picture-modal/update-picture-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ConfirmDeletionModalComponent } from './components/confirm-deletion-modal/confirm-deletion-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TeamAnalyticsComponent } from './components/team-analytics/team-analytics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HistogramChartComponent } from '../analytics/histogram-chart/histogram-chart.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    LayoutComponent,
    UpdatePictureModalComponent,
    ConfirmDeletionModalComponent,
    HistogramChartComponent,
    TeamAnalyticsComponent,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    LayoutComponent,
    TeamAnalyticsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ImageCropperModule,
    MatDialogModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxChartsModule,
  ],
})
export class SharedModule {}
