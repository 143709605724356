import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { IAPIResponse } from '@shared/models/api';
import { ILoginCredentials, IDeviceIDResponse } from '@shared/models/auth';
import { Router } from '@angular/router';
import { OperatorService } from '@services/api-services/operator.service';
import { LoginService } from '@services/api-services/login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private deviceID: string = null;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private operatorService: OperatorService,
    private loginService: LoginService) {

    if ('deviceID' in localStorage) {
      this.deviceID = localStorage.getItem('deviceID');
    }
  }

  public isLoggedIn(): boolean {
    if ('accessKey' in localStorage) {
      const accessKey = localStorage.getItem('accessKey');
      return accessKey.length >= 180;
    }

    return false;
  }

  private _sendCredentials(email: string, password: string, setLoggedInStatus: (isLoggedIn: boolean) => void ) {

    this.operatorService.login(email, password, this.deviceID).subscribe(
      (res) => {
        if (res.success === true) {
          localStorage.setItem('accessKey', res.data.accessToken);
          localStorage.setItem('refreshKey', res.data.refreshToken);
          localStorage.setItem('changePassword', res.data.changePassword.toString());
          localStorage.setItem('deviceID', this.deviceID);

          setLoggedInStatus(true);

          if (res.data.changePassword) {
            this.router.navigate(['/account']);
          }
          else {
            this.router.navigate(['/main-page']);
          }
        }
      },
      (err) => {
        setLoggedInStatus(false);
      }
    );
  }

  private _sendCredentialsWebClubAdmin(email: string, password: string, setLoggedInStatus: (isLoggedIn: boolean) => void ) {

    this.loginService.loginWebClubAdmin(email, password, this.deviceID).subscribe(
      (res) => {
        if (res.success === true) {
          localStorage.setItem('accessKey', res.data.accessToken);
          localStorage.setItem('refreshKey', res.data.refreshToken);
          localStorage.setItem('changePassword', res.data.changePassword.toString());
          localStorage.setItem('deviceID', this.deviceID);

          setLoggedInStatus(true);

          if (res.data.changePassword) {
            this.router.navigate(['/account']);
          }
          else {
            this.router.navigate(['/main-page']);
          }
        }
      },
      (err) => {
        setLoggedInStatus(false);
      }
    );
  }

  public getNewDeviceID(): Observable<IDeviceIDResponse> {
    return this.httpClient.get<IDeviceIDResponse>(`${environment.apiBaseURL}/uuid`);
  }

  public deviceIDIsSet(): boolean {
    return localStorage.getItem('deviceID') != null;
  }

  public logIn(email: string, password: string, setLoggedInStatus: (isLoggedIn: boolean) => void ) {

    if (this.deviceIDIsSet()) {
      this._sendCredentials(email, password, setLoggedInStatus);
    }
    else {
      this.getNewDeviceID().subscribe(
        (res: IDeviceIDResponse) => {
          this.deviceID = res.uuid;
          localStorage.setItem('deviceID', res.uuid);

          this._sendCredentials(email, password, setLoggedInStatus);
        }
      );
    }
  }

  public logInWebClubAdmin(email: string, password: string, setLoggedInStatus: (isLoggedIn: boolean) => void ) {

    if (this.deviceIDIsSet()) {
      this._sendCredentialsWebClubAdmin(email, password, setLoggedInStatus);
    }
    else {
      this.getNewDeviceID().subscribe(
        (res: IDeviceIDResponse) => {
          this.deviceID = res.uuid;
          localStorage.setItem('deviceID', res.uuid);

          this._sendCredentialsWebClubAdmin(email, password, setLoggedInStatus);
        }
      );
    }
  }

  public refreshAccessToken(): Observable<any> {

    const refreshKey = localStorage.getItem('refreshKey');

    return new Observable(observer => {
      this.operatorService.refreshAccessToken(refreshKey).subscribe(
        (res: IAPIResponse<ILoginCredentials>) => {
          localStorage.setItem('accessKey', res.data.accessToken);

          if (res.data.refreshToken != null) {
            localStorage.setItem('refreshKey', res.data.refreshToken);
          }

          observer.next();
          observer.complete();
        });
      }
    );
  }

  get accessToken(): string {
    return localStorage.getItem('accessKey');
  }

  public shouldChangePassword() {
    return localStorage.getItem('changePassword') === 'true';
  }
}
