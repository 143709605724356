import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IAPIResponse } from '@shared/models/api';
import { ILoginCredentials } from '@shared/models/auth';
import { IOperator } from '@shared/models/operator';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private readonly baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = `${environment.apiBaseURL}/backend/login`;
  }

  private _url(endpoint: string): string {
    return `${this.baseURL}/${endpoint}`;
  }

  public loginWebClubAdmin(emailaddress: string, password: string, deviceID?: string): Observable<IAPIResponse<ILoginCredentials>> {
    return this.httpClient.post<IAPIResponse<ILoginCredentials>>(this._url('web'),
      { emailaddress, password, deviceID });
  }

  public loginWebTeam(username: string, password: string, diveshotID: string): Observable<IAPIResponse<ILoginCredentials>> {
    return this.httpClient.post<IAPIResponse<ILoginCredentials>>(this._url('web'),
      { username, password, diveshotID });
  }

  public getGeneratedQRCode(){
    return this.httpClient.post<IAPIResponse<any>>(this._url('generateQR'),{});
  }
}
