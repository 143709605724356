<div style="display: flex; flex-direction: column; overflow-y: none;">
    <span id="universalnavbar">
    <app-navbar></app-navbar>
    </span>
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <span id="universalsidebar"> 
        <app-sidebar></app-sidebar>
        </span>
        <div style="flex: 1; padding: 2%; overflow-y: auto; max-height: 95vh;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>