import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IAPIResponse } from '@shared/models/api';
import { ILoginCredentials } from '@shared/models/auth';
import { IOperator } from '@shared/models/operator';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  private readonly baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = `${environment.apiBaseURL}/backend/operator`;
  }

  private _url(endpoint: string): string {
    return `${this.baseURL}/${endpoint}`;
  }

  public getAll(): Observable<IAPIResponse<IOperator[]>> {
    const adminBaseURL = `${environment.adminAPIBaseURL}/operator/list/`;

    return this.httpClient.get<IAPIResponse<IOperator[]>>(adminBaseURL);
  }

  public login(email: string, password: string, deviceID?: string): Observable<IAPIResponse<ILoginCredentials>> {
    return this.httpClient.put<IAPIResponse<ILoginCredentials>>(this._url('login'),
      { email, password, deviceID });
  }

  public logout(): Observable<IAPIResponse<object>> {
    return this.httpClient.get<IAPIResponse<object>>(this._url('logout'));
  }

  public refreshAccessToken(refreshToken: string): Observable<IAPIResponse<ILoginCredentials>> {
    return this.httpClient.get<IAPIResponse<ILoginCredentials>>(this._url('tokens'),
      { headers: { 'Refresh-Token': refreshToken } });
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<IAPIResponse<any>> {
    return this.httpClient.put<IAPIResponse<any>>(this._url('password'), { oldPassword, newPassword });
  }

  public updateData(clubName: any, firstName: any, lastName: any): Observable<IAPIResponse<any>> {
    return this.httpClient.post<IAPIResponse<any>>(this._url('updatedata'), { clubName, firstName, lastName });
  }

  public getClubAndOperatorInfo(){
    return this.httpClient.post<IAPIResponse<any>>(this._url('getcluboperatorinfo'), { });
  }
}
