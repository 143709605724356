import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorService } from '@services/api-services/operator.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {

  isEnabled: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly operatorService: OperatorService
  ) {
    const getAdmin = localStorage.getItem('isAdmin');
    const getUsername = localStorage.getItem('username');
    if (getAdmin === "false" && getUsername !== undefined) {
      this.isEnabled = false;
    }else{
      this.isEnabled = true;
    }
  }

  goTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  logoutUser(): void {
    this.operatorService.logout().subscribe(
      (res) => {
        localStorage.removeItem('accessKey');
        localStorage.removeItem('changePassword');
        localStorage.removeItem('deviceID');
        localStorage.removeItem('email');
        localStorage.removeItem('refreshKey');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('username');
        this.router.navigate(['/login']);
      },
      (err) => {
        // console.log(err);
      }
    );
  }
}
